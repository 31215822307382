import './common.js'

$(document).on("turbolinks:load", function() {

  if($(".wrap-cases-index")[0]) {
    $('#case-list tr td:not(.not-select)').on('click', function() {
      _U.show_events($(this).parent().data('cid'));
    });

    window._U.show_events = function(cid) {
      if($('#panel-events').hasClass('d-none')) {
        $('#panel-cases').removeClass('col-md-12').addClass('col-md-6');
        $('#panel-events').removeClass('d-none');  
      }

      $('#case-list tr').removeClass('active');
      $('#tr-'+cid).addClass('active');
      
      $.ajax({
        type: "GET",
        url: "/case/events/"+cid,
        dataType: 'html'
      }).done(function( rhtml ) { 
        $("#panel-events").html( rhtml );

        $('#close-events').on('click', function() {
          window._U.hide_events();
        });
      }); 
    }

    window._U.hide_events = function() {
      if(!$('#panel-events').hasClass('d-none')) {
        $('#panel-cases').removeClass('col-md-6').addClass('col-md-12');
        $('#panel-events').addClass('d-none');  
      }
    }
  }

  if($(".wrap-cases-show")[0]) {
    $('#event_event_date').datetimepicker({
      format: 'L'
    });

    $('#event_event_time').datetimepicker({
      format: 'HH:mm'
    });

    $('#write-event').on('click', function() {
      $('.wrap-write-event').toggleClass('d-none');
      if(!$('.wrap-write-event').hasClass('d-none')) {
        $("#new_event")[0].reset();
      }
    });
    
  }

  if($(".wrap-cases-new,.wrap-cases-edit")[0]) {
    const set_category = function(ac) {
      if(ac=="확진자") {
        $('.ct-isolation').addClass('d-none');
        $('.ct-confirm').removeClass('d-none');
      } else {
        $('.ct-isolation').removeClass('d-none');
        $('.ct-confirm').addClass('d-none');
      }
    }

    const set_group = function(ag) {
      if(ag=="장애인") {
        $('.wrap-detail-info').removeClass('d-none');
      } else {
        $('.wrap-detail-info').addClass('d-none');
      }
    }

    $("input[name='case[category]']").on('change', function() {
      set_category($(this).val());
    });
    $("input[name='case[case_group]']").on('change', function() {
      set_group($(this).val());
    });

    $('#case_isolation_date,#case_isolation_end_date,#case_confirm_date,#case_discharge_date,#case_hospital_date,#case_write_date').datetimepicker({
      format: 'L'
    });

    $('.select2-single').select2({
      placeholder: '추가할 코드를 선택하세요.'
    });

    set_category($('#cur_category').val());
    set_group($('#cur_group').val());
  
  }
});